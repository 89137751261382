<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('high_Honor')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('high_Honor')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-if="datatable.filterStatus"
                              @filter="filter"
                              @filterClear="filterClear"
                              :exportExcel="checkPermission('student_highhonorexportexcel')"
                              @exportExcel="excellExport">
                <b-row>
                    <b-row class="mr-2">
                        <b-col sm="6" md="4" lg="3" xl="12">
                            <b-form-group :label="$t('students')">
                                <lined-textarea v-model="datatable.queryParams.filter.student_number"
                                                :nowrap="false"
                                                :disabled="false"
                                                :styles="{ height: '110px', resize: 'both' }">
                                </lined-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4" lg="3" xl="12">
                            <b-form-group :label="$t('academic_year')">
                                <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year">
                                </academic-years-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4" lg="3" xl="12">
                            <b-form-group :label="$t('name')">
                                <b-form-input type="text" size="sm"
                                              v-model="datatable.queryParams.filter.name"></b-form-input>
                            </b-form-group>
                        </b-col>

                    </b-row>
                    <b-row>
                        <b-col sm="6" md="4" lg="3" xl="12">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox
                                    v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4" lg="3" xl="12">
                            <b-form-group :label="$t('program')">
                                <program-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                                   major_type="A"
                                                   v-model="datatable.queryParams.filter.program_code"></program-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4" lg="3" xl="12">
                            <b-form-group :label="$t('class')">
                                <parameter-selectbox v-model="datatable.queryParams.filter.class"
                                                     code="classes"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="6" md="4" lg="3" xl="12">
                            <b-form-group :label="$t('surname')">
                                <b-form-input type="text" size="sm"
                                              v-model="datatable.queryParams.filter.surname"/>

                            </b-form-group>
                        </b-col>

                    </b-row>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            ></datatable>
        </app-layout>
    </div>
</template>
<script>
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import HighHonorService from "@/services/HighHonorService";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import LinedTextarea from '@/components/elements/LinedTextarea';
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import qs from 'qs'
import ClassroomService from "@/services/ClassroomService";

export default {
    components: {
        AppLayout,
        AcademicYearsSelectbox,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        FacultySelectbox,
        ProgramSelectbox,
        ParameterSelectbox,
        LinedTextarea
    },
    metaInfo() {
        return {
            title: this.$t('high_Honor')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.$t('academic_year'),
                        field: 'academic_year',
                        hidden: false,
                    },
                    {
                        label: this.$t('semesters'),
                        field: 'academic_semester',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('student_number'),
                        field: 'student_number',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('name_surname'),
                        field: 'name_surname',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('faculty_name'),
                        field: ('faculty_name'),
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('class'),
                        field: 'class',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('program_name'),
                        field: ('program_name'),
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('program_code'),
                        field: 'program_code',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('registration_type'),
                        field: ('registration_type'),
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('gpa'),
                        field: 'gpa',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('high_honor_status'),
                        field: 'honor_status',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                    {
                        label: this.$t('semester_status'),
                        field: 'semester_status',
                        hidden: false,
                        tdClass: 'text-center',
                        thClass: 'text-center',
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        excellExport() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            ClassroomService.downloadAsExcel(config)
                .then(res => this._downloadFile(res, this.$t('high_honor') + '.xlsx'))
                .catch(err => this.showErrors(err))
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterSet() {
            this.datatable.queryParams.filter = {
                id: null,
                name: null,
                surname:null
            };
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return HighHonorService.getAll(config)
                .then(response => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        }
    }
};
</script>

